













































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { api } from "@/api";
import {
  readHasAdminAccess,
  readResult,
  readSample,
  readUserProfile,
} from "@/store/main/getters";
import {
  ICOACreateAssignSection,
  ICOASectionSetup,
  ICOASetup,
  ITemplate,
  ITemplateCreate,
  ITemplateDetails,
  ITestType,
} from "@/interfaces";
import { callApi } from "@/store/main/actions";
import NavBar from "@/components/NavBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import CoaCreatePreview from "@/components/CoaCreatePreview.vue";
import CoaCreateOptions from "@/components/CoaCreateOptions.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

const createAssignSection = (section: ICOASectionSetup): ICOACreateAssignSection => {
  return {
    coa_section_id: section.id,
    coa_section_options: section.optionSetups.map((o) => {
      return {
        coa_section_option_definition_id: o.optionDefinition.id,
        value: o.value,
      };
    }),
  };
};

const createAssignPayload = (coaSetup: ICOASetup): ICOACreateAssignSection[] => {
  const assignSections: ICOACreateAssignSection[] = [];
  if (coaSetup.base) {
    assignSections.push(createAssignSection(coaSetup.base));
  }
  if (coaSetup.header) {
    assignSections.push(createAssignSection(coaSetup.header));
  }
  if (coaSetup.main) {
    assignSections.push(createAssignSection(coaSetup.main));
  }
  if (coaSetup.footer) {
    assignSections.push(createAssignSection(coaSetup.footer));
  }
  if (coaSetup.definitions) {
    assignSections.push(createAssignSection(coaSetup.definitions));
  }
  for (let extraSection of coaSetup.extra) {
    assignSections.push(createAssignSection(extraSection));
  }
  return assignSections;
};

extend("required", { ...required, message: "{_field_} can not be empty" });

@Component({
  components: {
    NavBar,
    NavDrawer,
    CoaCreatePreview,
    CoaCreateOptions,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CoaCreate extends Vue {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };

  templates: ITemplate[] = [];
  selectedTemplate: ITemplate | null = null;
  selectedTemplateDetails: ITemplateDetails | null = null;
  defaultTemplate: ITemplateDetails | null = null;
  testType: ITestType | null = null;
  loadFirstTemplate = true;
  coaSections: ICOACreateAssignSection[] = [];
  coaSetup: ICOASetup | null = null;
  coaName = "New Certificate";
  editingName = false;
  publish = false;
  creating = false;
  optionsOverlay = false;

  // Create Template Dialog
  showCreateTemplateDialog = false;
  newTemplateName = "";
  templateFor = "";
  isTemplateUpdate = false;

  get result() {
    const result = readResult(this.$store);
    if (!result || result.id !== +this.$route.params.id) {
      return null;
    }
    return result;
  }

  get sample() {
    const sample = readSample(this.$store);
    if (!sample || sample.sample_number !== this.$route.params.sampleId) {
      return null;
    }
    return sample;
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get isSuperAdmin() {
    return false;
  }

  get userProfile() {
    return readUserProfile(this.$store);
  }

  get isMultiResult() {
    return !this.$route.params.id && this.$route.params.sampleId;
  }

  async updateTestType(test_type_id: number) {
    const response = await callApi(this.$store, () => {
      return api.getTestType(test_type_id);
    });
    if (response?.data) {
      this.testType = response.data;
    }
  }

  async updateDefaultTemplate(test_type_id: number) {
    const response = await callApi(this.$store, () => {
      return api.getDefaultTemplate(test_type_id);
    });
    if (response?.data) {
      this.defaultTemplate = response.data;
    }
  }

  @Watch("result")
  async onResultChanged() {
    if (
      this.result &&
      (!this.testType || this.testType.id !== this.result.test_type_id)
    ) {
      await Promise.all([
        this.updateTestType(this.result.test_type_id),
        this.updateDefaultTemplate(this.result.test_type_id),
      ]);
    }
  }

  async updateMultiResultTestType(sampleId: string) {
    const response = await callApi(this.$store, () => {
      return api.getMultiResultTestType(sampleId);
    });
    if (response?.data) {
      this.testType = response.data;
    }
  }

  async updateDefaultMultiResultTemplate(sampleId: string) {
    const response = await callApi(this.$store, () => {
      return api.getDefaultMultiResultTemplate(sampleId);
    });
    if (response?.data) {
      this.defaultTemplate = response.data;
      this.templates = [this.defaultTemplate];
      this.selectedTemplate = this.defaultTemplate;
      this.selectedTemplateDetails = this.defaultTemplate;
    }
  }

  @Watch("sample")
  async onSampleChanged() {
    if (this.sample) {
      await Promise.all([
        this.updateMultiResultTestType(this.sample.sample_number),
        this.updateDefaultMultiResultTemplate(this.sample.sample_number),
      ]);
      this.coaSetup = null;
      this.coaSections = [];
    }
  }

  @Watch("selectedTemplate")
  async onSelectedTemplateChange() {
    if (this.selectedTemplate && !this.isMultiResult) {
      const templateId = this.selectedTemplate.id
      const response = await callApi(this.$store, () => {
        return api.getTemplateDetails(templateId);
      });
      if (response?.data) {
        this.coaSetup = null;
        this.coaSections = [];
        this.selectedTemplateDetails = response.data;
      }
    }
  }

  @Watch("isTemplateUpdate")
  onIsTemplateUpdateChange() {
    this.newTemplateName =
      this.isTemplateUpdate && this.selectedTemplate
        ? this.selectedTemplate.name
        : "";
    this.templateFor =
      this.isTemplateUpdate && this.selectedTemplate && this.selectedTemplate.public
        ? "public"
        : this.isTemplateUpdate &&
          this.selectedTemplate &&
          this.selectedTemplate.parent_company_id
        ? "company"
        : "personal";
  }

  applyOptions(coaSetup: ICOASetup) {
    this.optionsOverlay = false;
    this.coaSetup = coaSetup;
    this.coaSections = createAssignPayload(coaSetup);
  }

  toggleShowCreateTemplateDialog(coaSetup: ICOASetup) {
    this.coaSetup = coaSetup;
    this.coaSections = createAssignPayload(coaSetup);
    this.isTemplateUpdate = false;
    this.onIsTemplateUpdateChange();
    this.showCreateTemplateDialog = true;
  }

  getTemplateCreatePayload(): ITemplateCreate | null {
    if (this.testType) {
      return {
        name: this.newTemplateName,
        test_type_id: this.testType?.id,
        coa_sections: this.coaSections,
        result_id: this.templateFor === "company" ? this.result?.id : undefined,
        public: this.templateFor === "public",
      };
    }
    return null;
  }

  async createTemplate() {
    const success = await this.$refs.observer.validate();

    if (!success) {
      return;
    }

    const payload = this.getTemplateCreatePayload();
    if (payload) {
      if (this.isTemplateUpdate && this.selectedTemplate) {
        const templateId = this.selectedTemplate.id;
        const response = await callApi(this.$store, () => {
          return api.updateTemplate(templateId, payload);
        });
        if (response?.data) {
          const idx = this.templates.indexOf(this.selectedTemplate);
          if (idx > -1) {
            this.templates.splice(idx, 1, response.data);
          }
          this.selectedTemplate = response.data;
        }
      } else {
        const response = await callApi(this.$store, () => {
          return api.createTemplate(payload);
        });
        if (response?.data) {
          this.templates.push(response.data);
          this.selectedTemplate = response.data;
        }
      }
      this.showCreateTemplateDialog = false;
    }
  }

  isTemplateEditable(template: ITemplate | null): boolean {
    return (
      (!template?.key_name && this.isAdmin) ||
      (!!template?.user_id && template?.user_id === this.userProfile?.id) ||
      (!!template?.parent_company_id &&
        template?.parent_company_id === this.result?.parent_company_id)
    );
  }

  public async mounted() {
    this.onResultChanged();
    this.onSampleChanged();
    if (!this.isMultiResult) {
      const response = await callApi(this.$store, () => {
        return api.getTemplates(+this.$route.params.id);
      });
      if (response?.data) {
        this.templates = response.data;
        if (this.loadFirstTemplate && this.templates.length > 0) {
          // TODO: This is a little goofy, we may want better integration with
          // BOSS test types in the future.
          // Default to the Colorado template if this was ordered as a Colorado test
          if (this.result?.test_type_boss_key?.endsWith("Co")) {
            this.selectedTemplate =
              this.templates.find((t) => t.key_name?.endsWith("_colorado")) ??
              this.templates[0];
          } else {
            this.selectedTemplate = this.templates[0];
          }
          this.loadFirstTemplate = false;
        }
      }
    }
  }

  async onCreateClick() {
    const resultID = +this.$route.params.id;
    this.creating = true;
    const response = await callApi(this.$store, () => {
      return api.createCOA({
        result_id: resultID,
        sample_id: this.$route.params.sampleId,
        name: this.coaName,
        published: this.publish,
        template_id: this.selectedTemplate?.id || null,
        coa_sections: this.coaSections,
      });
    });
    this.creating = false;
    if (response?.data) {
      if (this.$route.params.sampleId) {
        this.$router.replace({
          name: "coa-sample",
          params: {
            sampleId: this.$route.params.sampleId,
            coaId: response.data.id.toString(),
          },
        });
      } else {
        this.$router.replace({
          name: "coa",
          params: {
            id: this.$route.params.id,
            coaId: response.data.id.toString(),
          },
        });
      }
    }
  }
}
