






























































































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import {
  ICOASection,
  ICOASectionOption,
  ICOASectionOptionDefinition,
  ITemplateCOASection,
  ITemplateDetails,
  ITestType,
  SectionType,
  ICOASectionSetup,
  ICOASectionOptionSetup,
  ICOASetup,
  ICompany,
} from "@/interfaces";
import CoaSectionList from "./CoaSectionList.vue";
import { readHasAdminAccess } from "@/store/main/getters";
import {
  dispatchGetResultChildCompanies,
  dispatchGetSampleChildCompanies,
} from "@/store/main/actions";

const getSectionsByType = (coaSections: ICOASection[], sectionType: SectionType) => {
  return coaSections.filter((section) => {
    return section.section_type === sectionType;
  });
};

const cloneCOASectionSetup = (original: ICOASectionSetup): ICOASectionSetup => {
  /*
  Deep clone an ICOASectionSetup object.
  */
  return {
    id: original.id,
    name: original.name,
    key_name: original.key_name,
    sectionType: original.sectionType,
    optionSetups: original.optionSetups.map((optionSetup) => {
      return { ...optionSetup };
    }),
  };
};

const setupEquals = (setup1: ICOASectionSetup, setup2: ICOASectionSetup): boolean => {
  /*
  Check if two ICOASectionSetup objects are equivalent.
  */
  if (
    setup1.id !== setup2.id ||
    setup1.optionSetups.length !== setup2.optionSetups.length
  ) {
    return false;
  }
  for (let optionSetup1 of setup1.optionSetups) {
    const optionSetup2 = setup2.optionSetups.find(
      (optionSetup: ICOASectionOptionSetup) => {
        return optionSetup1.optionDefinition.id === optionSetup.optionDefinition.id;
      },
    );
    if (!optionSetup2 || optionSetup2.value !== optionSetup1.value) {
      return false;
    }
  }
  return true;
};

@Component({
  components: {
    CoaSectionList,
  },
})
export default class CoaCreateOptions extends Vue {
  @Prop({ default: null })
  templateDetails!: ITemplateDetails | null;
  @Prop({ default: null })
  defaultTemplateDetails!: ITemplateDetails | null;
  @Prop({ default: null })
  testType!: ITestType | null;

  // local changes that have been saved (applied to the preview)
  @Prop({ default: null })
  coaSetup!: ICOASetup | null;

  // All sections the COA could include, from testType
  baseSections: ICOASection[] = [];
  headerSections: ICOASection[] = [];
  mainContentSections: ICOASection[] = [];
  extraContentSections: ICOASection[] = [];
  footerSections: ICOASection[] = [];
  definitionsSections: ICOASection[] = [];

  // Reference (used to detect changes)
  templateBase: ICOASectionSetup | null = null;
  templateHeader: ICOASectionSetup | null = null;
  templateMain: ICOASectionSetup | null = null;
  templateFooter: ICOASectionSetup | null = null;
  templateDefinitions: ICOASectionSetup | null = null;
  templateExtraSections: ICOASectionSetup[] = [];
  // Object to store user edits
  selectedHeader: ICOASectionSetup | null = null;
  selectedBase: ICOASectionSetup | null = null;
  selectedMain: ICOASectionSetup | null = null;
  selectedFooter: ICOASectionSetup | null = null;
  selectedDefinitions: ICOASectionSetup | null = null;
  selectedExtraSections: ICOASectionSetup[] = [];

  editsPending = false;

  includePhotos = false;
  childCompanies: ICompany[] = [];

  @Watch("defaultTemplateDetails")
  onDefaultTemplateDetailsChange() {
    this.update();
  }

  @Watch("templateDetails")
  onTemplateDetailsChange() {
    this.update();
  }

  @Watch("testType")
  onTestTypeChange() {
    this.update();
  }

  @Watch("selectedBase", { deep: true })
  onSelectedBaseChange() {
    this.updateEditsPending();
  }

  @Watch("selectedHeader", { deep: true })
  onSelectedHeaderChange() {
    this.updateEditsPending();
  }

  @Watch("selectedMain", { deep: true })
  onSelectedMainChange() {
    this.updateEditsPending();
  }

  @Watch("selectedFooter", { deep: true })
  onSelectedFooterChange() {
    this.updateEditsPending();
  }

  @Watch("selectedDefinitions", { deep: true })
  onSelectedDefinitionsChange() {
    this.updateEditsPending();
  }

  @Watch("selectedExtraSections", { deep: true })
  onSelectedExtraSectionsChange() {
    this.updateEditsPending();
  }

  @Watch("includePhotos")
  onIncludePhotosChange() {
    if (this.includePhotos) {
      if (
        !this.selectedExtraSections.some((s) => {
          return s.key_name === "extra_content_photos";
        })
      ) {
        const photosSection = this.extraContentSections.find((s) => {
          return s.key_name === "extra_content_photos";
        });
        if (photosSection) {
          this.selectedExtraSections.push(
            this.createCOASectionSetup(photosSection, []),
          );
        }
      }
    } else {
      this.selectedExtraSections = this.selectedExtraSections.filter((s) => {
        return s.key_name !== "extra_content_photos";
      });
    }
  }

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  get isSuperAdmin() {
    return false;
  }

  get allSelectedSections() {
    return [
      this.selectedHeader,
      this.selectedBase,
      this.selectedMain,
      this.selectedFooter,
      this.selectedDefinitions,
    ]
      .concat(this.selectedExtraSections)
      .filter((section) => section !== null);
  }

  updateEditsPending() {
    this.editsPending =
      (!!this.templateBase &&
        !!this.selectedBase &&
        !setupEquals(this.templateBase, this.selectedBase)) ||
      (!!this.templateHeader &&
        !!this.selectedHeader &&
        !setupEquals(this.templateHeader, this.selectedHeader)) ||
      (!!this.templateMain &&
        !!this.selectedMain &&
        !setupEquals(this.templateMain, this.selectedMain)) ||
      (!!this.templateFooter &&
        !!this.selectedFooter &&
        !setupEquals(this.templateFooter, this.selectedFooter)) ||
      (!!this.templateDefinitions &&
        !!this.selectedDefinitions &&
        !setupEquals(this.templateDefinitions, this.selectedDefinitions)) ||
      this.selectedExtraSections.length !== this.templateExtraSections.length ||
      !this.selectedExtraSections.reduce((prev: boolean, curr: ICOASectionSetup) => {
        // This cycles through the selectedExtraSections and checks to see if each is
        // equivalent to a counterpart in templateExtraSections (if not, then the user
        // has changes pending)
        if (!prev) {
          return false;
        }
        const templateSect = this.templateExtraSections.find((sect) => {
          return sect.id === curr.id;
        });
        return !!templateSect && setupEquals(curr, templateSect);
      }, true);
  }

  public async update() {
    if (this.testType && this.templateDetails && this.defaultTemplateDetails) {
      this.baseSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.BASE,
      );
      this.headerSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.HEADER,
      );
      this.mainContentSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.MAIN_CONTENT,
      );
      this.extraContentSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.EXTRA_CONTENT,
      );
      this.footerSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.FOOTER,
      );
      this.definitionsSections = getSectionsByType(
        this.testType.coa_sections,
        SectionType.DEFINITIONS,
      );

      // Base Sections
      const defaultBase = this.getTemplateSection(
        this.defaultTemplateDetails.template_coa_sections,
        this.baseSections,
      );
      const templateBase = this.getTemplateSection(
        this.templateDetails.template_coa_sections,
        this.baseSections,
      );
      this.templateBase = this.coaSetup?.base ?? templateBase ?? defaultBase;
      this.selectedBase = this.templateBase
        ? cloneCOASectionSetup(this.templateBase)
        : null;

      // Header Sections
      const defaultHeader = this.getTemplateSection(
        this.defaultTemplateDetails.template_coa_sections,
        this.headerSections,
      );
      const templateHeader = this.getTemplateSection(
        this.templateDetails.template_coa_sections,
        this.headerSections,
      );
      this.templateHeader = this.coaSetup?.header ?? templateHeader ?? defaultHeader;
      this.selectedHeader = this.templateHeader
        ? cloneCOASectionSetup(this.templateHeader)
        : null;

      // Main Sections
      const defaultMain = this.getTemplateSection(
        this.defaultTemplateDetails.template_coa_sections,
        this.mainContentSections,
      );
      const templateMain = this.getTemplateSection(
        this.templateDetails.template_coa_sections,
        this.mainContentSections,
      );
      this.templateMain = this.coaSetup?.main ?? templateMain ?? defaultMain;
      this.selectedMain = this.templateMain
        ? cloneCOASectionSetup(this.templateMain)
        : null;

      // Footer Sections
      const defaultFooter = this.getTemplateSection(
        this.defaultTemplateDetails.template_coa_sections,
        this.footerSections,
      );
      const templateFooter = this.getTemplateSection(
        this.templateDetails.template_coa_sections,
        this.footerSections,
      );
      this.templateFooter = this.coaSetup?.footer ?? templateFooter ?? defaultFooter;
      this.selectedFooter = this.templateFooter
        ? cloneCOASectionSetup(this.templateFooter)
        : null;

      // Definitions Sections
      const defaultDefinitions = this.getTemplateSection(
        this.defaultTemplateDetails.template_coa_sections,
        this.definitionsSections,
      );
      const templateDefinitions = this.getTemplateSection(
        this.templateDetails.template_coa_sections,
        this.definitionsSections,
      );
      this.templateDefinitions =
        this.coaSetup?.definitions ?? templateDefinitions ?? defaultDefinitions;
      this.selectedDefinitions = this.templateDefinitions
        ? cloneCOASectionSetup(this.templateDefinitions)
        : null;

      // Extra Sections
      const defaultExtraSections = this.getTemplateSections(
        this.defaultTemplateDetails.template_coa_sections,
        this.extraContentSections,
      );
      const templateExtraSections = this.getTemplateSections(
        this.templateDetails.template_coa_sections,
        this.extraContentSections,
      );
      this.templateExtraSections =
        this.coaSetup && this.coaSetup?.extra.length > 0
          ? this.coaSetup.extra
          : templateExtraSections.length > 0
          ? templateExtraSections
          : defaultExtraSections;
      this.selectedExtraSections = this.templateExtraSections.map((t) =>
        cloneCOASectionSetup(t),
      );

      this.includePhotos = this.selectedExtraSections.some((s) => {
        return s.key_name === "extra_content_photos";
      });
    }
  }

  async mounted() {
    this.update();
    const resultID = +this.$route.params.id;
    if (resultID) {
      const resp = await dispatchGetResultChildCompanies(this.$store, resultID);
      if (resp) {
        this.childCompanies = resp.data;
      }
    }

    const sampleID = this.$route.params.sampleId;
    if (sampleID) {
      const resp = await dispatchGetSampleChildCompanies(this.$store, sampleID);
      if (resp) {
        this.childCompanies = resp.data;
      }
    }
  }

  onBaseSelected(section: ICOASection) {
    this.selectedBase = this.createCOASectionSetup(section, []);
  }

  onHeaderSelected(section: ICOASection) {
    this.selectedHeader = this.createCOASectionSetup(section, []);
  }

  onMainSelected(section: ICOASection) {
    this.selectedMain = this.createCOASectionSetup(section, []);
  }

  onFooterSelected(section: ICOASection) {
    this.selectedFooter = this.createCOASectionSetup(section, []);
  }

  onDefinitionsSelected(section: ICOASection) {
    this.selectedDefinitions = this.createCOASectionSetup(section, []);
  }

  onExtraSectionAdded(section: ICOASection) {
    this.selectedExtraSections.push(this.createCOASectionSetup(section, []));
  }

  onExtraSectionRemoved(section: ICOASection) {
    this.selectedExtraSections = this.selectedExtraSections.filter((s) => {
      return s.id !== section.id;
    });
  }

  getICOASetup(): ICOASetup {
    return {
      base: this.selectedBase,
      header: this.selectedHeader,
      main: this.selectedMain,
      footer: this.selectedFooter,
      definitions: this.selectedDefinitions,
      extra: this.selectedExtraSections,
    };
  }

  @Emit("apply")
  apply(): ICOASetup {
    /*
    Emits an ICOASetup object for the parent component to store 
    and pass back to this component in the coaSetup Prop
    */
    return this.getICOASetup();
  }

  applyClicked() {
    if (this.editsPending) {
      this.apply();
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.$emit("cancel");
  }

  @Emit("save-template")
  saveAsTemplate() {
    return this.getICOASetup();
  }

  createCOASectionSetup(
    section: ICOASection,
    coaSectionOptions: ICOASectionOption[],
  ): ICOASectionSetup {
    /*
    Combine an ICOASection object with ICOASectionOption objects so that
    option definitions and option values are together and easily iterable.

    Also filter out options that are restricted if not an admin.
    */
    const filteredSections = section.coa_section_option_definitions.filter(
      (optionDef: ICOASectionOptionDefinition) => {
        return !optionDef.restricted || !!this.isAdmin;
      },
    );

    const optionSetups: ICOASectionOptionSetup[] = filteredSections.map(
      (optionDef: ICOASectionOptionDefinition): ICOASectionOptionSetup => {
        const option = coaSectionOptions.find((option: ICOASectionOption) => {
          return option.coa_section_option_definition_id === optionDef.id;
        });
        return { optionDefinition: optionDef, value: option?.value };
      },
    );

    return {
      id: section.id,
      name: section.name,
      key_name: section.key_name,
      sectionType: section.section_type,
      optionSetups,
    };
  }

  getTemplateSection(
    templateCOASections: ITemplateCOASection[],
    coaSections: ICOASection[],
  ): ICOASectionSetup | null {
    /* 
    Find a section from templateCOASections that is in coaSections and if found
    return a ICOASectionSetup object with the section and section options.
    */
    let templateSection: ITemplateCOASection | undefined = undefined;
    let section: ICOASection | undefined = undefined;
    for (templateSection of templateCOASections) {
      section = coaSections.find((section: ICOASection) => {
        return section.id === templateSection?.coa_section_id;
      });
      if (section) {
        break;
      }
    }
    if (templateSection && section) {
      return this.createCOASectionSetup(section, templateSection.coa_section_options);
    }
    return null;
  }

  getTemplateSections(
    templateCOASections: ITemplateCOASection[],
    coaSections: ICOASection[],
  ): ICOASectionSetup[] {
    /* 
  Find multiple sections from templateCOASections that are in coaSections 
  and if found return an ICOASectionSetup object for each.
  */
    const sectionSetups: ICOASectionSetup[] = [];
    for (let templateSection of templateCOASections) {
      const section = coaSections.find((section: ICOASection) => {
        return section.id === templateSection?.coa_section_id;
      });
      if (section) {
        sectionSetups.push(
          this.createCOASectionSetup(section, templateSection.coa_section_options),
        );
      }
    }
    return sectionSetups;
  }
}
