export interface IUserProfile {
  email: string;
  is_active: boolean;
  is_admin: boolean;
  full_name: string;
  id: number;
}

export interface IUserProfileUpdate {
  email?: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_admin?: boolean;
}

export interface IUserProfileCreate {
  email: string;
  full_name?: string;
  password?: string;
  is_active?: boolean;
  is_admin?: boolean;
}

export interface IPagination {
  total: number;
  offset: number;
}

export interface IResult {
  id: number;
  order_id: string;
  sample_id: string;
  test_id: string;
  batch_id: string;
  date_submitted: string;
  date_reported: string;
  test_type_id: number;
  test_type: ITestType;
  parent_company_id: number;
  company_id: number;
  test_type_boss_key?: string;
}

export interface IResultWithCoas extends IResult {
  coas: ICOA[];
}

export interface IResultsWrapper {
  pagination: IPagination;
  results: IResult[];
}

export interface IResultsWrapperWithCoas {
  pagination: IPagination;
  results: IResultWithCoas[];
}

export interface IColumnValuesWrapper {
  pagination: IPagination;
  values: string[];
}

export interface IParentCompaniesWrapper {
  pagination: IPagination;
  parent_companies: IParentCompany[];
}

export interface ICompaniesWrapper {
  pagination: IPagination;
  companies: ICompany[];
}

export interface ICompany extends IBaseObject {
  company_name: string;
}

export interface IParentCompany extends IBaseObject {
  parent_company_name: string;
  child_companies: ICompany[];
}

export interface ICompanyInfo {
  parent_company: IParentCompany;
  child_company: ICompany;
}

export interface ICOA {
  id: number;
  template_id: number | null;
  published: boolean;
  date_published: string;
  public_url: string;
  url: string;
  creation_date: string;
  name: string | null;
  uuid: string | null;
  version: number | null;
}

export interface ICOASectionOptionDefinition {
  id: number;
  name: string;
  default: string | null;
  option_type: string;
  restricted: boolean;
  required: boolean;
  key_name: string;
}

export interface ICOASection {
  id: number;
  name: string;
  key_name: string;
  section_type: SectionType;
  coa_section_option_definitions: ICOASectionOptionDefinition[];
}

export interface ITemplate {
  id: number;
  name: string;
  key_name?: string;
  parent_company_id?: number;
  user_id?: number;
  public: boolean;
}

export interface ITemplateDetails extends ITemplate {
  template_coa_sections: ITemplateCOASection[];
}

export interface ICOASectionOption {
  coa_section_option_definition_id: number;
  value: string;
}

export interface ITemplateCOASection {
  coa_section_id: number;
  coa_section_options: ICOASectionOption[];
}

export interface ITestType extends IBaseObject {
  test_name: string;
  coa_sections: ICOASection[];
}

export interface ITestTypesWrapper {
  pagination: IPagination;
  test_types: ITestType[];
}

export enum SectionType {
  BASE = "base",
  HEADER = "header",
  MAIN_CONTENT = "main_content",
  EXTRA_CONTENT = "extra_content",
  FOOTER = "footer",
  DEFINITIONS = "definitions",
}

export interface ITemplateCreate {
  name: string;
  test_type_id: number;
  coa_sections: ICOACreateAssignSection[];
  result_id: number | undefined;
  public: boolean | undefined;
}

export interface IBaseObject {
  id: number;
}

export interface IVideoDevice {
  id: string;
  text: string;
}

export interface ISampleImage {
  sample_id: string | null;
  archived: boolean;
  id: number | null;
  key: string | null;
  url: string | null;
}

export interface ISampleImageEdit {
  file: File;
  url: string;
  data: ISampleImage;
}

export interface ISample {
  id: number;
  sample_name: string;
  batch_name: string;
  sample_number: string;
  order_id: number;
}

export interface ISamplePageImages {
  sample_id: string;
  images: ISampleImage[];
}

// API COA Creation

export interface ICOACreateAssignOption {
  coa_section_option_definition_id: number;
  value: string | number | boolean | undefined;
}

export interface ICOACreateAssignSection {
  coa_section_id: number;
  coa_section_options: ICOACreateAssignOption[];
}

// Internal COA Creation Interfaces

// An object to hold option definition and the value, for iterating over in markup
export interface ICOASectionOptionSetup {
  optionDefinition: ICOASectionOptionDefinition;
  value: string | number | boolean | undefined;
}

// Includes options for coa section
export interface ICOASectionSetup {
  id: number;
  name: string;
  key_name: string;
  sectionType: SectionType;
  optionSetups: ICOASectionOptionSetup[];
}

// Contains all setup for a COA
export interface ICOASetup {
  base: ICOASectionSetup | null;
  header: ICOASectionSetup | null;
  main: ICOASectionSetup | null;
  footer: ICOASectionSetup | null;
  definitions: ICOASectionSetup | null;
  extra: ICOASectionSetup[];
}

export interface IBreadcrumb {
  disabled?: boolean;
  exact?: boolean;
  href?: string;
  link?: boolean;
  text: string | number;
  to: string | Record<string, unknown>;
}
