






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { api } from "@/api";
import { ICOACreateAssignSection, ITemplate } from "@/interfaces";
import { callApi } from "@/store/main/actions";
import PdfPreview from "@/components/PdfPreview.vue";

@Component({
  components: {
    PdfPreview,
  },
})
export default class CoaCreatePreview extends Vue {
  @Prop({ default: null })
  template!: ITemplate | null;
  @Prop({ type: Number })
  resultId!: number | null;
  @Prop({ type: String })
  sampleId!: string | null;
  @Prop({ default: () => [] })
  coaSections!: ICOACreateAssignSection[];
  pdfData: Uint8Array | null = null;

  @Watch("coaSections")
  onCOASectionsChange() {
    /**
     * By watching coaSections we catch coaSection changes and template changes
     * since a template change resets the coaSections. We don't watch both
     * separately to avoid calling the preview endpoint twice.
     */
    this.pdfData = null;
    this.update();
  }

  public async update() {
    const response = await callApi(this.$store, () => {
      return api.getCOAPreview({
        result_id: this.resultId,
        sample_id: this.sampleId,
        // Don't pass template if multi_result coa, we want to use the default
        template_id: (this.resultId && this.template?.id) || null,
        coa_sections: this.coaSections,
      });
    });
    if (response?.data) {
      this.pdfData = new Uint8Array(await response.data.arrayBuffer());
    }
  }
}
