



























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import pdf from "vue-pdf";

@Component({
  components: {
    pdf,
  },
})
export default class PdfPreview extends Vue {
  @Prop({ default: null })
  pdfData!: Uint8Array | null;

  @Prop({ default: 100 })
  defaultZoom!: number;

  @Prop({ default: 200 })
  zoomUpperLimit!: number;

  @Prop({ default: 30 })
  zoomLowerLimit!: number;

  @Prop({ default: 10 })
  zoomIncrements!: number;

  zoom = 100;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  srcData: any = null;
  numPages = 0;

  get pdfStyle() {
    return {
      width: `${this.zoom}%`,
    };
  }

  @Watch("pdfData")
  async onPdfDataUpdate() {
    if (!this.pdfData) {
      return;
    }
    this.srcData = pdf.createLoadingTask(this.pdfData);
    const pdfSrc = await this.srcData.promise;
    this.numPages = pdfSrc.numPages;
  }

  mounted() {
    this.zoom = this.defaultZoom;
  }

  zoomIn() {
    let result = this.zoom + this.zoomIncrements;
    if (result < this.zoomUpperLimit) {
      this.zoom = result;
    } else {
      this.zoom = this.zoomUpperLimit;
    }
  }

  zoomOut() {
    let result = this.zoom - this.zoomIncrements;
    if (result > this.zoomLowerLimit) {
      this.zoom = result;
    } else {
      this.zoom = this.zoomLowerLimit;
    }
  }
}
