













import { ICOASection, ICOASectionSetup } from "@/interfaces";
import { readHasAdminAccess } from "@/store/main/getters";
import { Component, Vue, Prop } from "vue-property-decorator";
import CoaSectionCard from "./CoaSectionCard.vue";

@Component({
  components: {
    CoaSectionCard,
  },
})
export default class CoaSectionList extends Vue {
  @Prop({ default: () => [] })
  sections!: ICOASection[];
  @Prop({ default: () => null })
  selectedSection!: ICOASectionSetup | null;

  // Props for multi-select support
  @Prop({ type: Boolean, default: false })
  multiple!: boolean;
  @Prop({ default: () => [] })
  selectedSections!: ICOASectionSetup[];

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }

  onSelectSection(section: ICOASection) {
    if (!this.multiple) {
      if (section.id !== this.selectedSection?.id) {
        this.$emit("selected", section);
      }
    } else {
      const currentlySelected = this.selectedSections.find((selectedSect) => {
        return selectedSect.id === section.id;
      });
      if (!currentlySelected) {
        this.$emit("add-section", section);
      } else {
        this.$emit("remove-section", section);
      }
    }
  }

  isSelected(section: ICOASection) {
    if (!this.multiple) {
      return this.selectedSection && section.id === this.selectedSection.id;
    } else {
      return !!this.selectedSections.find((selectedSect) => {
        return selectedSect.id === section.id;
      });
    }
  }

  getSectionIfSelected(section: ICOASection): ICOASectionSetup | undefined {
    if (!this.multiple) {
      return this.selectedSection && section.id === this.selectedSection.id
        ? this.selectedSection
        : undefined;
    } else {
      return this.selectedSections.find((selectedSect) => {
        return selectedSect.id === section.id;
      });
    }
  }
}
