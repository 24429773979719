

















import { ICOASection, ICOASectionSetup } from "@/interfaces";
import { readHasAdminAccess } from "@/store/main/getters";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CoaSectionCard extends Vue {
  @Prop()
  section!: ICOASection;
  @Prop({ default: null })
  sectionSetup!: ICOASectionSetup | null | undefined;
  @Prop({ type: Boolean, default: false })
  selected!: boolean;

  get isAdmin() {
    return readHasAdminAccess(this.$store);
  }
}
